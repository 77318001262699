import TYPES from '@/types';

import { currencyFormat } from '@/vue-app/utils/currency';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class LinkedCustomInvestorGoalBudgetBodyCardViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace = 'components.goals-dashboard.linked-custom-investor-goal-card';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  getAmountFormatted(amount: number) {
    return `${currencyFormat(amount)} MXN`;
  }
}
