












































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import LinkedCustomInvestorGoalBudgetBodyCardViewModel from '@/vue-app/view-models/components/goals-dashboard/linked-goals/linked-custom-investor-goal-budget-body-card-view-model';

// Domain
import {
  LinkedCustomInvestorGoalBudgetCardDataDto,
} from '@/modules/flagship/investor-goal/linked-investor-goal/domain/dto/linked-custom-investor-goal-budget-card-data-dto';
import {
  GoalTrackingEntity,
} from '@/modules/flagship/investor-goal/goal-tracking/domain/entities/goal-tracking-entity';

@Component({
  name: 'LinkedCustomInvestorGoalBudgetBodyCard',
  components: {
    CustomProgressBar: () => import('@/vue-app/components/goals-dashboard/details-goals/CustomProgressBar.vue'),
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class LinkedCustomInvestorGoalBudgetBodyCard extends Vue {
  @PropSync('cardData', { type: Object, required: true })
  card_data!: LinkedCustomInvestorGoalBudgetCardDataDto;

  @PropSync('goalTrackingEntity', { type: Object })
  goal_tracking_entity!: GoalTrackingEntity;

  view_model = Vue.observable(new LinkedCustomInvestorGoalBudgetBodyCardViewModel());
}
